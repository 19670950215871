import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentDto, ErrorDetails, FileParameter, FormDataTypeEnum, FormItemDto, FormSectionDto, FormTypeAudienceEnum, FormTypeDto, PatientClient, PatientFormDto, SettingsClient, StationaryDto, StorageContentTypeEnum } from '../../../shared/services/api.service';
import { AuthenticationService } from '../../../shared/services/auth';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SignatureDialogComponent, SignatureDialogData, SignatureDialogResult, SignatureType } from '../../../shared/signature-dialog/signature-dialog.component';
import html2pdf from 'html2pdf.js';
import * as moment from 'moment';

@Component({
    selector: 'patient-form',
    templateUrl: './patient-form.component.html',
    styleUrls: ['./patient-form.component.css']
})
export class PatientFormComponent implements OnInit, OnDestroy {
    @ViewChild('formContent', { static: false }) formContent: ElementRef;
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    today: any;
    isWorking: boolean;
    isGeneratingPdf: boolean;
    patientId: number;
    contactId: number;
    maskedTenantKey: string;
    patientForms: PatientFormDto[];
    selectedPatientForm: PatientFormDto;
    selectedFormType: FormTypeDto;
    stationaryHeader: SafeHtml;
    stationaryFooter: SafeHtml;
    formDataTypeEnum: typeof FormDataTypeEnum = FormDataTypeEnum;
    formGroup: FormGroup;
    page: number = 1;
    testFiles = [];

    constructor(
        public matDialog: MatDialog,
        public patientClient: PatientClient,
        public settingsClient: SettingsClient,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _snackbar: MatSnackBar,
        private _sanitizer: DomSanitizer,
        private _matDialog: MatDialog,
    ) { }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    ngOnInit(): void {
        this.isWorking = true;
        this.contactId = this._authService.getContactId();
        this.patientId = this._authService.getPatientId();
        this.maskedTenantKey = this._authService.getBrowserMaskedTenantKey();

        this.getPatientForms();
    }

    getPatientForms(): void {
        this.patientClient.patient_GetPatientForms(
            this.patientId,
            this.contactId,
            null,
            null,
            this.maskedTenantKey
        )
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (forms: PatientFormDto[]) => {
                    this.patientForms = forms;

                    if (forms.length === 1) {
                        this.selectPatientForm(forms[0]);
                    }

                    this.isWorking = false;
                },
                (error) => {
                    console.log(error);
                    this._snackbar.open('An Error has occured!', 'OK', { duration: 3000 });
                    this.isWorking = false;
                });
    }

    selectPatientForm(patientForm: PatientFormDto): void {
        this.isWorking = true;
        this.formGroup = null;
        this.selectedPatientForm = patientForm;
        this.selectedFormType = null;
        this.stationaryHeader = null;
        this.stationaryFooter = null;
        this.page = 1;
        this.isGeneratingPdf = false;

        this.settingsClient.settings_GetFormType(
            this.selectedPatientForm.formTypeId
        )
            .pipe(
                switchMap((formType: FormTypeDto) => {
                    this.selectedFormType = formType;
                    if (formType.stationaryId)
                        return this.patientClient.patient_GetPatientStationary(
                            this.patientId,
                            formType.stationaryId
                        )
                    else
                        return of(null);
                }),
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (stationary: StationaryDto) => {
                    if (stationary) {
                        this.stationaryHeader = this._sanitizer.bypassSecurityTrustHtml(stationary.header);
                        this.stationaryFooter = this._sanitizer.bypassSecurityTrustHtml(stationary.footer);
                    }
                    
                    this.initializeSelectedFormType();
                    this.isWorking = false;
                },
                (error) => {
                    console.log(error);
                    this._snackbar.open('An Error has occured!', 'OK', { duration: 3000 });
                    this.isWorking = false;
                });
    }

    initializeSelectedFormType(): void {
        let group = {};
        this.formGroup = null;
        this.selectedFormType.formSections.forEach((formSection: FormSectionDto) => {
            this.initializedFormItemsFormGroup(formSection.formItems, group);
        });
        this.formGroup = this._formBuilder.group(group);
    }

    initializedFormItemsFormGroup(formItems: FormItemDto[], group: any) {
        formItems.forEach((formItem: FormItemDto) => {
            let validators: ValidatorFn[] = [];

            if (formItem.formDataType != FormDataTypeEnum.Row &&
                formItem.formDataType != FormDataTypeEnum.Container &&
                formItem.formDataType != FormDataTypeEnum.RadioOption &&
                formItem.formDataType != FormDataTypeEnum.Filler) {
                switch (formItem.formDataType) {
                    case FormDataTypeEnum.Text:
                    case FormDataTypeEnum.TextArea: {
                        if (formItem.isRequired) {
                            validators.push(Validators.required);
                        }
                        validators.push(Validators.minLength(formItem.minimunLength));
                        validators.push(Validators.maxLength(formItem.maximumLength));
                        break;
                    }
                    case FormDataTypeEnum.Email: {
                        if (formItem.isRequired) {
                            validators.push(Validators.required);
                        }
                        validators.push(Validators.email);
                        break;
                    }
                    case FormDataTypeEnum.Phone: {
                        if (formItem.isRequired) {
                            validators.push(Validators.required);
                        }
                        validators.push(Validators.pattern('[- +()0-9]+'));
                        break;
                    }
                    case FormDataTypeEnum.Number: {
                        if (formItem.isRequired) {
                            validators.push(Validators.required);
                        }
                        validators.push(Validators.min(formItem.minimunLength));
                        validators.push(Validators.max(formItem.maximumLength));
                        break;
                    }
                    case FormDataTypeEnum.CheckBox: {
                        if (formItem.isRequired) {
                            validators.push(Validators.requiredTrue);
                        }
                        break;
                    }
                    case FormDataTypeEnum.Date:
                    case FormDataTypeEnum.Radio:
                    case FormDataTypeEnum.Attachments:
                    case FormDataTypeEnum.Signature: {
                        if (formItem.isRequired) {
                            validators.push(Validators.required);
                        }
                        break;
                    }
                }

                group[`${formItem.formDataType}_${formItem.id}`] = new FormControl(
                    '',
                    validators
                );
            }

            this.initializedFormItemsFormGroup(formItem.children, group);
        });
    }

    cancel(): void {
        this.reset();
    }

    reset(): void {
        this.formGroup = null;
        this.selectedPatientForm = null;
        this.selectedFormType = null;
        this.stationaryHeader = null;
        this.stationaryFooter = null;
        this.getPatientForms();
    }

    openSignatureWindow(type: SignatureType, controlName: string) {
        const data: SignatureDialogData = { type: type };
        let config: { [x: string]: string | number } = {};
        config['maxWidth'] = '100vw';
        if (type == 'full') {
            config['width'] = '90vw';
        } else if (type == 'initials') {
            config['width'] = '500px';
        }
        this._matDialog
            .open(SignatureDialogComponent, { data: data, disableClose: true, ...config })
            .afterClosed()
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((result: SignatureDialogResult) => {
                if (result) {
                    this.formGroup.controls[controlName].setValue(result.value);
                }
            });
    }

    submit(): void {
        this.isWorking = true;
        this.isGeneratingPdf = true;
        this.generatePdf().then(async (blob: Blob) => {
            if (!blob) {
                this.isWorking = false;
                this.isGeneratingPdf = false;
                this._snackbar.open('An Error has occured!', 'OK', { duration: 3000 });
                return;
            }

            let formattedDate = moment().format('MM/DD/YYYY');
            const data = new Blob([blob], { type: blob.type })
            const file: FileParameter = {
                data,
                fileName: `${this.selectedFormType.name}_${formattedDate}.pdf`,
            };
            const jsonData: string = await this.generateJsonData();

            this.patientClient
                .patient_PostPatientForm(
                    this.patientId,
                    this.selectedPatientForm.id,
                    null,
                    file,
                    StorageContentTypeEnum.PatientForm,
                    jsonData,
                    this.contactId
                )
                .pipe(take(1))
                .subscribe(
                    (_) => {
                        this.isWorking = false;
                        this.isGeneratingPdf = false;
                        this._snackbar.open('Form submitted!', 'OK', { duration: 3000 });
                        this.reset();
                    },
                    (err: ErrorDetails) => {
                        this.isWorking = false;
                        this.isGeneratingPdf = false;
                        this._snackbar.open('An Error has occured!', 'OK', { duration: 3000 });
                    }
                );
        });
    }

    async generateJsonData(): Promise<string> {
        let jsonData: string = '';

        let answer: any = {
            formName: this.selectedFormType.name,
            description: this.selectedFormType.description,
            sections: []
        }

        for await (const s of this.selectedFormType.formSections) {
            let section: any = {
                label: s.sectionLabel,
                formItems: []
            }

            for await (const i of s.formItems) {
                let formItemAnswer = {
                    question: i.paragraph,
                    formDataType: i.formDataType,
                    answer: this.formGroup && this.formGroup.get(`${i.formDataType}_${i.id}`) ? this.formGroup.get(`${i.formDataType}_${i.id}`).value : null,
                    children: []
                };
                await this.generateFormItemsAnswer(i, formItemAnswer);
                section.formItems.push(formItemAnswer);
            };

            answer.sections.push(section);
        };

        jsonData = JSON.stringify(answer);

        return jsonData;
    }

    async generateFormItemsAnswer(formItem: FormItemDto, formItemAnswer: any): Promise<void> {
        for await (const i of formItem.children) {
            let answer: any = this.formGroup && this.formGroup.get(`${i.formDataType}_${i.id}`) ? this.formGroup.get(`${i.formDataType}_${i.id}`).value : null;
            if (i.formDataType == FormDataTypeEnum.Attachments && answer != null && answer.length > 0) {
                answer = await this.postAttachment(answer);
            }

            let childAnswer = {
                question: i.paragraph,
                formDataType: i.formDataType,
                answer: answer,
                children: []
            };
            await this.generateFormItemsAnswer(i, childAnswer);

            formItemAnswer.children.push(childAnswer);
        };
    }

    async postAttachment(localFileAttachments: File[]): Promise<AttachmentDto[]> {
        return new Promise(resolve => {
            let files: FileParameter[] = [];

            localFileAttachments.forEach((f: File, i: number) => {
                const data = new Blob([f], { type: f.type })
                files.push({
                    data,
                    fileName: f.name.replace(new RegExp('/', 'g'), '_'),
                });
            });

            this.patientClient.patient_PostCreateAttachment(
                this.patientId,
                files
            ).pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((res) => {
                resolve(res);
            });
        });
    }

    generatePdf(): Promise<Blob> {
        let content = this.formContent.nativeElement;
        var opt = {
            margin: [0, 0],
            filename: 'SignOff.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        return new Promise(resolve => html2pdf().from(content).set(opt).outputPdf('blob').then((result) => {
            resolve(result);
        }));
    }

    getLocalFileAttachment(formItem: FormItemDto): any {
        let localFilesControl = this.formGroup.get(`${formItem.formDataType}_${formItem.id}`);
        return localFilesControl ? localFilesControl.value : null;
    }

    localFileAttachmentChange(event, formItem: FormItemDto): void {
        this.formGroup.get(`${formItem.formDataType}_${formItem.id}`).setValue([...event]);
    }

    removeLocalFileAttachment(formItem: FormItemDto, i: number): void {
        let attachments = this.getLocalFileAttachment(formItem);
        if (!attachments || attachments.length < 1) return;
        if (i > -1) {
            attachments.splice(i, 1);
        }
        this.formGroup.get(`${formItem.formDataType}_${formItem.id}`).setValue(attachments);
    }
}
